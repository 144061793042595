<table style="width: 100%">
  <div class="container-fluid">
    <div style="float: right; margin-top: 0px; font-size: small">
      {{ this.created_on }}
    </div>
    <div class="column left" style="margin-top: -15px; margin-right: 30px">
      <img
        [src]="client_information?.logo_path_light"
        width="110px"
        height="150px"
        [alt]="client_information?.website"
      />
    </div>

    <div
      style="
        position: relative;
        align-items: left;
        justify-content: left;
        text-align: left;
      "
    >
      <div style="padding-left: 50px">
        <h2 style="padding-top: 5px">
          {{ client_information?.name?.toUpperCase() }}
        </h2>
        <p style="margin: 0; padding: 0px">
          {{ client_information?.address }}
        </p>
        <p style="margin: 0; padding: 0px">
          Tel :
          {{
            client_information?.phone_number?.dialCode +
              client_information?.phone_number?.number
          }}
        </p>
        <p style="margin: 0; padding: 0px">
          Email:
          {{ client_information?.email }}
        </p>
      </div>
      <div
        style="
          margin-top: 33px;
          justify-content: center;
          text-align: center;
          padding-right: 8px;
        "
      >
        <p style="font-size: 20px; margin-top: 2px">
          <strong>
            {{
              proposal_information?.proposer_details?.customer_type ===
              'INDIVIDUAL'
                ? ('policies.proposal.pdf.proposal-form-medical-ind'
                  | translate)
                : ('policies.proposal.pdf.proposal-form-medical' | translate)
            }}
          </strong>
          <br />
          <strong>
            <u>
              {{ 'policies.proposal.pdf.quotation-number' | translate }}-{{
                ref_number
              }}
            </u>
          </strong>
        </p>
      </div>

      <div class="content" style="padding-top: -20px; margin-top: -20px">
        <br />
        <div>
          <div>
            <!------ Proposer Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  01.
                  {{ 'policies.proposal.add-edit.steppers.1' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.type-pdf'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        proposal_information?.proposer_details?.customer_type ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.name'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        getName(proposal_information?.proposer_details?.name)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.engagement-pdf'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        titleCasePipe.transform(
                          proposal_information?.proposer_details?.engagement
                        )
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.contact'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                      proposal_information?.proposer_details?.contact?.[0]
                      ? getPhone(
                      proposal_information?.proposer_details?.contact
                      )
                      : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.email'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                      proposal_information?.proposer_details?.email?.[0]
                      ? getEmail(
                      proposal_information?.proposer_details?.email
                      )
                      : '-'
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.fax'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.fax
                          ? getPhone(
                              proposal_information?.proposer_details?.fax
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.identification_number'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details
                          ?.identification_number || '-'
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.occupation'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.occupation ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.vat_number'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.vat_number ||
                          '-'
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.svat_number'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.svat_number ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.communication-mode-pdf'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{ communication_modes }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.communication-language-pdf'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{ communication_languages }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.start-date'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.start_date
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.proposer_details?.start_date
                            )
                          : ''
                      }}
                    </td>
                    <th class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.end-date'
                          | translate
                      }}
                    </th>
                    <td class="table_result">
                      {{
                        proposal_information?.proposer_details?.end_date
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.proposer_details?.end_date
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <!-- Medical Group Sections -->
            <!-- Scheme And Sum Assured Info -->
            <ng-container *ngIf="isMedicalGroup">
              <p class="table_caption">
                <strong> 02. SCHEME INFORMATION </strong>
              </p>

              <ng-container
                *ngFor="
                  let scheme of proposal_information
                    ?.medical_group_scheme_products?.productMedicalGroupScheme;
                  let i = index
                "
              >
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">Scheme</th>
                      <td width="50%" class="table_result">
                        {{ scheme?.scheme || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Scheme Count</th>
                      <td width="50%" class="table_result">
                        {{ scheme?.count || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Product Code</th>
                      <td width="50%" class="table_result">
                        {{ scheme?.product_code || '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />

                <ng-container
                  *ngFor="let breakup of scheme?.scheme_breakups; let j = index"
                >
                  <table
                    width="100%"
                    border="1"
                    style="border-collapse: collapse; text-align: left"
                  >
                    <tbody>
                      <tr>
                        <th width="50%" class="table_header">
                          Scheme Breakup (Coverage)
                        </th>
                        <td width="50%" class="table_result">
                          {{ getCoverageName(breakup?.breakup) || '-' }}
                        </td>
                      </tr>
                      <tr>
                        <th width="50%" class="table_header">Member Count</th>
                        <td width="50%" class="table_result">
                          {{ breakup?.member_count || '-' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />

                  <ng-container
                    *ngFor="let cover of breakup?.covers_list; let k = index"
                  >
                    <table
                      width="100%"
                      border="1"
                      style="border-collapse: collapse; text-align: left"
                    >
                      <tbody>
                        <tr>
                          <th width="50%" class="table_header">Cover</th>
                          <td width="50%" class="table_result">
                            {{ getCoverName(cover?.cover) || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Sum Assured (LKR)
                          </th>
                          <td width="50%" class="table_result">
                            {{ cover?.sum_assured || '-' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <br />
                </ng-container>
              </ng-container>

              <!-- Floater -->

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">Scheme</th>
                    <td width="50%" class="table_result">Floater</td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">Product Code</th>
                    <td width="50%" class="table_result">
                      {{ floaterData?.product_code || '-' }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />

              <ng-container
                *ngFor="let cover of floaterData?.covers_list; let k = index"
              >
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">Cover</th>
                      <td width="50%" class="table_result">
                        {{ getCoverName(cover?.cover) || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        Sum Assured (LKR)
                      </th>
                      <td width="50%" class="table_result">
                        {{ cover?.sum_assured || '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <br />
            </ng-container>

            <!-- Members Info Info -->
            <!-- <ng-container *ngIf="isMedicalGroup">
              <p class="table_caption">
                <strong> 02. Members Information </strong>
              </p>

              <ng-container
                *ngFor="
                  let member of proposal_information?.medical_group_members_information;
                  let i = index
                "
              >
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">Name</th>
                      <td width="50%" class="table_result">
                        {{ member?.name || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">NIC</th>
                      <td width="50%" class="table_result">
                        {{ member?.nic || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Mobile Number</th>
                      <td width="50%" class="table_result">
                        {{ member?.mobile_number || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Date of Birth</th>
                      <td width="50%" class="table_result">
                        {{ getFormattedDate(member?.date_of_birth) || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">Employee Number</th>
                      <td width="50%" class="table_result">
                        {{ member?.employee_number || '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </ng-container>
              <br />
            </ng-container> -->

            <!-- Insured Section -->
            <ng-container *ngIf="!isMedicalGroup">
              <p class="table_caption">
                <strong>
                  02.
                  {{
                    'policies.proposal.add-edit.steppers.2-medical' | translate
                  }}
                </strong>
              </p>
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_1'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_1
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_1'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_1
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_2'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_2
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_3'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_3
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_4'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_4
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_5'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_5
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_6'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_6
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_7'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_7
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_8'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_8
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_9'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_9
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_10'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_10
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_11'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_11
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_12'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_12
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_13'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.question_13
                            ?.value
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_14'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details
                            ?.alcohol_consumption?.value
                        )
                      }}
                    </td>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.type'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        proposal_information?.insured_details
                          ?.alcohol_consumption?.type
                          ? proposal_information?.insured_details
                              ?.alcohol_consumption?.type
                          : '-'
                      }}
                    </td>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.weekly-consumption'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        proposal_information?.insured_details
                          ?.alcohol_consumption?.amount
                          ? proposal_information?.insured_details
                              ?.alcohol_consumption?.amount +
                            ' ' +
                            proposal_information?.insured_details
                              ?.alcohol_consumption?.rate
                          : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_15'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details
                            ?.smoke_consumption?.value
                        )
                      }}
                    </td>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.type'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        proposal_information?.insured_details?.smoke_consumption
                          ?.type
                          ? proposal_information?.insured_details
                              ?.smoke_consumption?.type
                          : '-'
                      }}
                    </td>
                    <th colspan="2" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.daily-consumption'
                          | translate
                      }}
                    </th>
                    <td colspan="2" class="table_result">
                      {{
                        proposal_information?.insured_details?.smoke_consumption
                          ?.amount
                          ? proposal_information?.insured_details
                              ?.smoke_consumption?.amount +
                            ' ' +
                            proposal_information?.insured_details
                              ?.smoke_consumption?.rate
                          : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_17'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details?.has_good_health
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="8" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.insured-person.question_18'
                          | translate
                      }}
                    </th>
                    <td colspan="4" class="table_result">
                      {{
                        getStringForBoolean(
                          proposal_information?.insured_details
                            ?.pregnant_details?.value
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <!-- Member Section -->
            <ng-container
              *ngIf="
                !isMedicalGroup &&
                proposal_information?.member_details.length > 0
              "
            >
              <p class="table_caption">
                <strong>
                  03.
                  {{
                    'policies.proposal.add-edit.steppers.3-medical' | translate
                  }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <ng-container
                    *ngFor="let member of proposal_information?.member_details"
                  >
                    <tr>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.details-of-insured.name'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{ member?.name }}
                      </td>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.details-of-insured.relationship'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{ member?.relationship }}
                      </td>
                    </tr>
                    <tr>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.details-of-insured.dob'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{
                          dateTimeFormatService.formatDateWithoutTime(
                            member?.dob
                          )
                        }}
                      </td>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.details-of-insured.occupation'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{ member?.occupation ? member?.occupation : '-' }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>

            <!------ Additional Covers Stepper -------->
            <ng-container *ngIf="!isMedicalGroup">
              <p class="table_caption">
                <strong>
                  {{ coverSectionNumber }}
                  {{ 'policies.proposal.add-edit.steppers.7' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.additional-covers.hd1'
                          | translate
                      }}
                    </th>
                    <th
                      width="50%"
                      class="table_header"
                      style="text-align: right"
                    >
                      {{
                        'policies.proposal.add-edit.steppers.additional-covers.hd2'
                          | translate
                      }}
                    </th>
                  </tr>
                  <tr
                    *ngFor="
                      let cover of this.proposal_information
                        ?.additional_covers_details?.covers
                    "
                  >
                    <td width="50%" class="table_result">
                      {{ cover?.name }}
                    </td>
                    <td
                      width="50%"
                      class="table_result"
                      style="text-align: right"
                    >
                      {{ thousandSeparatorAdd(cover?.value) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <!-- Claim Section -->
            <!-- <ng-container>
              <p class="table_caption">
                <strong>
                  {{ isMedicalGroup ? '02.' : '05.' }}
                  {{
                    'policies.proposal.add-edit.steppers.4-medical' | translate
                  }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <ng-container
                    *ngFor="let claim of proposal_information?.claim_details"
                  >
                    <tr>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-claims.period'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{ claim?.period }}
                      </td>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-claims.total-claims'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{ claim?.totalClaims }}
                      </td>
                    </tr>
                    <tr>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-claims.opd-value'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{ claim?.opdValue }}
                      </td>
                      <th class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-claims.indoor-value'
                            | translate
                        }}
                      </th>
                      <td class="table_result">
                        {{ claim?.indoorValue ? claim?.indoorValue : '-' }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </ng-container> -->

            <!------ Credit Request Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  {{ creditSectionNumber }}
                  {{ 'policies.proposal.add-edit.steppers.2' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="25%" class="table_header">Payment Method</th>
                    <td width="25%" class="table_result">
                      {{
                        titleCasePipe.transform(
                          proposal_information?.credit_request_details
                            ?.payment_method
                        ) || '-'
                      }}
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      proposal_information?.credit_request_details
                        ?.payment_method === 'CREDIT'
                    "
                  >
                    <th width="25%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.credit-amount'
                          | translate
                      }}
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        proposal_information?.credit_request_details
                          ?.credit_amount || '-'
                      }}
                      Days
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      proposal_information?.credit_request_details
                        ?.payment_method === 'CREDIT'
                    "
                  >
                    <th width="25%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.credit-period'
                          | translate
                      }}
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        proposal_information?.credit_request_details
                          ?.credit_period || '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                *ngIf="
                  proposal_information?.credit_request_details?.schedules
                    ?.length > 0 &&
                  proposal_information?.credit_request_details
                    ?.payment_method === 'CREDIT'
                "
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <ng-container
                    *ngFor="
                      let sch of proposal_information?.credit_request_details
                        ?.schedules
                    "
                  >
                    <tr>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.date'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.days'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.amount'
                            | translate
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td width="33.3%" class="table_result">
                        {{
                          sch.date
                            ? dateTimeFormatService.formatDateWithoutTime(
                                sch.date
                              )
                            : '-'
                        }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ sch.number_of_days }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ sch.amount }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
                *ngIf="
                  proposal_information?.credit_request_details
                    ?.payment_method === 'CREDIT'
                "
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.remark'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.credit_request_details?.remark ||
                          '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>

      <p class="table_caption">
        <strong> {{ 'policies.proposal.pdf.declaration' | translate }}</strong>
      </p>
      <div style="margin: auto; text-align: justify">
        {{ 'policies.proposal.pdf.declaration-medical' | translate }}
      </div>

      <table
        width="100%"
        border="1"
        style="border-collapse: collapse; text-align: left; margin-top: 20px"
      >
        <tbody>
          <tr>
            <th width="50%" class="table_header">
              {{ 'policies.proposal.pdf.name-office' | translate }}
            </th>
            <td width="50%" class="table_result"></td>
          </tr>
          <tr>
            <th width="50%" class="table_header">
              {{ 'policies.proposal.pdf.date' | translate }}
            </th>
            <td width="50%" class="table_result"></td>
          </tr>
          <tr height="100px">
            <th width="50%" class="table_header">
              {{ 'policies.proposal.pdf.sign-with-stamp' | translate }}
            </th>
            <td width="50%" class="table_result"></td>
          </tr>
        </tbody>
      </table>

      <div
        class="footer"
        style="justify-content: center; text-align: center; margin-left: -10px"
      ></div>
    </div>
  </div>
</table>
