import { ENDPOINTS } from '@shared/constants';

import { EndpointParams } from './modules/types';

const BASE_URLS = {
  ASSETS: {
    IMAGES: `/assets/images`,
  },
  IDENTITIES_SUPERVISORS: `/identities/supervisors`,
  AUTH: `/auth`,
  CONNECTED_APPS: `/connected-apps`,
  CONNECTIONS: `/connections`,
  INTERNAL_CONNECTIONS: `/internal/connections`,
  IDENTITIES: `/identities`,
  INTEGRATIONS: {
    ENDPOINTS: `/integrations/endpoints`,
    MAPPING: `/integrations/mappings`,
    FILE_CONFIGS: `/integrations/file-configs`,
    FILE_UPLOADS: `/integrations/file-uploads`,
  },
  JOB_QUEUE: `/job-queue`,
  MODULES: `/modules`,
  PERSONALIZATION: `/personalization`,
  PRIVILEGES: `/privileges`,
  NOTIFICATION: `/notifications`,
  CONFIGURABLE_FIELDS: {
    VALIDATIONS: `/configurable-fields/validations`,
  },
  REFERENCES: {
    REFERENCE_CATEGORIES: `/reference-categories`,
    REFERENCES: `/references`,
    IAM_REFERENCE_CATEGORIES: `/iam/reference-categories`,
    IAM_REFERENCES: `/iam/references`,
    EXTERNAL_REFERENCE_CATEGORIES: `/external/reference-categories`,
    EXTERNAL_REFERENCES: `/external/references`,
    EXTERNAL_REFERENCES_PRODUCTS_GET_ALL: `/competitions/products-external/product-list`,
    CLIENT_REFERENCE_CATEGORIES: `/client/reference-categories`,
    CLIENT_REFERENCES: `/client/references`,
    TEMPLATES: `/templates`,
    FORMULA: `/formula`,
  },
  SETUP: {
    AUTHORIZERS: `/setup/iam-config/authorizers`,
    IAM_HANDSHAKE: `/setup/iam-config/handshake`,
    BACKUPS: `/setup/backups`,
    BACKUP_SCHEDULES: `/setup/backup-schedules`,
    LANGUAGES: `/setup/languages`,
    REFERENCE_MAPPINGS: `/setup/reference-mappings`,
    CURRENCIES: `/setup/currencies`,
    EXTERNAL_SYSTEMS: `/setup/external-systems`,
    EXTERNAL_SYSTEMS_KEYS: `/setup/external-systems/keys`,
  },
  STORAGE: {
    INTERNAL: '/internal/storage',
    INTERNAL_ENTITY: '/internal/storage/entity',
    INTERNAL_SHARE_INTERNALLY: '/internal/storage/share/internal',
  },
  // NEW BUSINESS
  POLICIES: `/policies`,
  COVER_NOTES: `/cover-notes`,
  ENDORSEMENTS: `/endorsements`,
  // FOMS
  // A
  ACTIVITIES: `/activities`,
  ACHIEVEMENTS: {
    DATA: `/achievements`,
    CONFIGURATIONS: `/achievements/configurations`,
    CONFIGURATIONS_LOCK_DATE: `/achievements/lock-date-configuration`,
    CONFIGURE_ACHIEVEMENT_TARGETS: `/achievements/configurations/targets`,
  },
  // C
  COMPETITIONS: `/competitions`,
  CUSTOMERS: `/customers`,
  CLAIMS: `/claims`,
  // L
  LEADS: `/leads`,
  // R
  RECRUITMENTS: `/recruitments`,
  RECEIPTS: `/receipts`,

  // I
  INVOICES: `/invoices`,
  // T
  TARGETS: {
    DATA: `/targets`,
    CONFIGURATIONS: `/targets/configurations`,
  },
  //P
  PAYOUTS: {
    DATA: `/payouts/transactions`,
    CONFIGURATIONS: `/payouts/configurations`,
  },
  PAYOUTS_ALL: `/payouts`,

  //ACCOUNTING
  CHART_OF_ACCOUNTS: `/chart-of-accounts`,
  ENTRIES: `/entries`,
};

/**
 *
 * NOTE: if you get an error here that might be due to missing cases.
 * Check whether you have added all the ENDPOINTS to cases in below switch or not
 *
 */
const getURLPath = (endpoint: EndpointParams): string => {
  // never change the return type
  switch (endpoint.endpoint) {
    // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //

    // AUTH
    case ENDPOINTS.AUTH_REFRESH_TOKEN:
      return `${BASE_URLS.AUTH}/token`;

    case ENDPOINTS.AUTH_LOGIN:
      return `${BASE_URLS.AUTH}/login`;

    case ENDPOINTS.AUTH_PROFILE_IMAGE_UPLOAD:
      return `${BASE_URLS.AUTH}/upload/profile-image`;

    case ENDPOINTS.AUTH_LOGIN_IMPERSONATE:
      return `${BASE_URLS.AUTH}/impersonate/login`;

    case ENDPOINTS.AUTH_CHECK_USER:
      return `${BASE_URLS.AUTH}/check`;

    case ENDPOINTS.AUTH_LOGOUT:
      return `${BASE_URLS.AUTH}/logout`;

    case ENDPOINTS.AUTH_FORGOT_PASSWORD:
      return `${BASE_URLS.AUTH}/password/forgot`;

    case ENDPOINTS.AUTH_VALIDATE_TOKEN:
      return `${BASE_URLS.AUTH}/token/validate`;

    case ENDPOINTS.AUTH_RESET_PASSWORD:
      return `${BASE_URLS.AUTH}/password/reset`;

    case ENDPOINTS.AUTH_REGISTER:
      return `${BASE_URLS.AUTH}/register`;

    case ENDPOINTS.AUTH_VERIFY:
      return `${BASE_URLS.AUTH}/verify`;

    case ENDPOINTS.AUTH_PROFILE:
      return `${BASE_URLS.AUTH}/profile`;

    case ENDPOINTS.AUTH_SEND_OTP:
      return `${BASE_URLS.AUTH}/otp/send`;

    case ENDPOINTS.AUTH_USERNAME:
      return `${BASE_URLS.AUTH}/username`;

    case ENDPOINTS.AUTH_CODE:
      return `${BASE_URLS.AUTH}/code`;

    // CONNECTED - APPS
    case ENDPOINTS.CONNECTED_APPS_CONNECT_NEW_APPS:
    case ENDPOINTS.CONNECTED_APPS_GET_ALL_APPS:
      return BASE_URLS.CONNECTED_APPS;

    case ENDPOINTS.CONNECTED_APPS_GET_ONE_APP:
    case ENDPOINTS.CONNECTED_APPS_UPDATE_ONE_APP:
    case ENDPOINTS.CONNECTED_APPS_DELETE_ONE_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_FIELDS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/fields`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_ROLES_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/roles`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_PERMISSIONS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/permissions`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_DOCUMENTATIONS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/docs`;

    case ENDPOINTS.CONNECTED_APPS_CONNECT_OR_DISCONNECT_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/${endpoint.params.status_change}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_REFERENCES_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/reference-categories/${endpoint.params.categoryId}/references`;

    case ENDPOINTS.CONNECTED_APPS_HANDSHAKE:
      return `${BASE_URLS.CONNECTED_APPS}/handshake`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_SECRETS:
      return `${BASE_URLS.CONNECTED_APPS}/generate-secrets`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_API_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/generate`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_CLIENT_CREDENTIALS:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/generate-client-credentials`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_CLIENT_SECRET:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/save-deleted-client-secret`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/save-deleted-api-key`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.CONNECTED_APPS_UPDATE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_SPECIFIC_GENERATED_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_GENERATED_KEYS:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.CONNECTED_APPS_DELETE_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_RESTORE_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}/restore`;

    //CONNECTIONS
    case ENDPOINTS.CONNECTIONS_GET_ALL:
      return BASE_URLS.CONNECTIONS;

    case ENDPOINTS.CONNECTIONS_UPDATE:
      return `${BASE_URLS.CONNECTIONS}/${endpoint.params.id}`;

    // CUSTOMERS
    case ENDPOINTS.CUSTOMERS_GET_ALL:
      return BASE_URLS.CUSTOMERS;
    case ENDPOINTS.CUSTOMERS_GET_ONE:
      return `${BASE_URLS.CUSTOMERS}/${endpoint.params.id}`;

    // CLAIMS
    case ENDPOINTS.CLAIMS_GET_ALL:
      return BASE_URLS.CLAIMS;

    // IDENTITIES
    case ENDPOINTS.IDENTITIES_CREATE_ONE:
    case ENDPOINTS.IDENTITIES_GET_ALL:
      return BASE_URLS.IDENTITIES;

    case ENDPOINTS.IDENTITIES_GET_ONE_PUBLIC_INFORMATION_ONLY:
      return `${BASE_URLS.IDENTITIES}/public/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTION_CREATE_ONE:
      return `${BASE_URLS.INTERNAL_CONNECTIONS}`;

    case ENDPOINTS.IDENTITIES_SUBORDINATES:
      return `${BASE_URLS.IDENTITIES}/subordinates`;

    case ENDPOINTS.IDENTITIES_SUBORDINATE_IDS:
      return `${BASE_URLS.IDENTITIES}/subordinate-ids`;

    case ENDPOINTS.IDENTITIES_SUPERVISORS:
      return `${BASE_URLS.IDENTITIES_SUPERVISORS}`;

    case ENDPOINTS.IDENTITIES_HIERARCHICAL:
      return `${BASE_URLS.IDENTITIES}/hierarchy`;

    case ENDPOINTS.IDENTITIES_GET_INTERNAL_FIELD:
      return `${BASE_URLS.IDENTITIES}/get-field-data`;

    case ENDPOINTS.IDENTITIES_GET_ONE:
      return `${BASE_URLS.IDENTITIES}/${endpoint.params.id}`;

    case ENDPOINTS.IDENTITIES_INTERNAL_FIELDS:
      return `${BASE_URLS.IDENTITIES}/internal-fields`;

    case ENDPOINTS.IDENTITIES_EXTERNAL_FIELDS:
      return `${BASE_URLS.IDENTITIES}/external-fields`;

    // INTEGRATION - ENDPOINTS

    case ENDPOINTS.INTEGRATION_ENDPOINTS_GET_ALL:
    case ENDPOINTS.INTEGRATION_ENDPOINTS_ADD_ONE:
      return BASE_URLS.INTEGRATIONS.ENDPOINTS;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_UPDATE_ONE:
    case ENDPOINTS.INTEGRATION_ENDPOINTS_DELETE_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}`;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_RESTORE_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}/restore`;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_VERIFY_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}/verify`;

    // INTEGRATION -MAPPINGS
    case ENDPOINTS.INTEGRATION_MAPPINGS_ADD_ONE:
    case ENDPOINTS.INTEGRATION_MAPPINGS_GET_ALL:
      return BASE_URLS.INTEGRATIONS.MAPPING;
    case ENDPOINTS.INTEGRATION_MAPPINGS_UPDATE_ONE:
    case ENDPOINTS.INTEGRATION_MAPPINGS_DELETE_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}`;
    case ENDPOINTS.INTEGRATION_MAPPINGS_RESTORE_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}/restore`;
    case ENDPOINTS.INTEGRATION_MAPPINGS_VERIFY_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}/verify`;

    // INTEGRATION - FILE-CONFIGS
    case ENDPOINTS.INTEGRATION_FILE_CONFIGS_GET_ALL:
    case ENDPOINTS.INTEGRATION_FILE_CONFIGS_UPDATE:
      return BASE_URLS.INTEGRATIONS.FILE_CONFIGS;

    //INTEGRATION - FILE-UPLOADS
    case ENDPOINTS.INTEGRATION_FILE_UPLOADS_GET_ALL:
    case ENDPOINTS.INTEGRATION_FILE_UPLOADS_ADD_ONE:
      return BASE_URLS.INTEGRATIONS.FILE_UPLOADS;
    case ENDPOINTS.INTEGRATION_FILE_UPLOADS_UPDATE_STATUS:
      return `${BASE_URLS.INTEGRATIONS.FILE_UPLOADS}/${endpoint.params.id}/status`;
    case ENDPOINTS.INTEGRATION_FILE_UPLOADS_GET_CONNECTIONS_WITH_FILE_APPROVAL_PERMISSION:
      return `${BASE_URLS.INTEGRATIONS.FILE_UPLOADS}/connections`;

    // JOB_QUEUE
    case ENDPOINTS.JOB_QUEUE_CHECK_STATUS:
      return `${BASE_URLS.JOB_QUEUE}/${endpoint.params.id}`;

    // INVOICES
    case ENDPOINTS.INVOICES_GET_ALL:
      return BASE_URLS.INVOICES;
    case ENDPOINTS.INVOICES_SHARE_INVOICE:
      return `${BASE_URLS.INVOICES}/share-invoice`;
    case ENDPOINTS.INVOICES_SHARE_PAYABLE_LINK:
      return `${BASE_URLS.INVOICES}/share-payable-link`;
    case ENDPOINTS.INVOICE_GET_BY_PARAMS:
      return `${BASE_URLS.INVOICES}/invoiceByParams`;
    case ENDPOINTS.INVOICES_HASH_PAYMENT_DETAILS:
      return `${BASE_URLS.INVOICES}/hash`;

    // RECEIPTS
    case ENDPOINTS.RECEIPTS_ADD_NEW:
    case ENDPOINTS.RECEIPTS_GET_ALL:
      return `${BASE_URLS.RECEIPTS}`;
    case ENDPOINTS.RECEIPTS_AGGREGATOR_NEW:
      return `${BASE_URLS.RECEIPTS}/aggregator/${endpoint.params.separateReceipts}`;
    case ENDPOINTS.RECEIPTS_GET_REQUEST_CANCELLATION_DATA:
      return `${BASE_URLS.RECEIPTS}/getAllRequestCancellationData`;
    case ENDPOINTS.RECEIPTS_GET_ALL_EXCESS_DATA:
      return `${BASE_URLS.RECEIPTS}/getAllExcessData`;
    case ENDPOINTS.RECEIPTS_GET_ONE:
    case ENDPOINTS.RECEIPTS_DELETE_ONE:
    case ENDPOINTS.RECEIPTS_UPDATE_ONE:
      return `${BASE_URLS.RECEIPTS}/${endpoint.params.id}`;
    case ENDPOINTS.RECEIPTS_ADD_CANCEL_REQUEST:
      return `${BASE_URLS.RECEIPTS}/${endpoint.params.id}/requestCancellation`;
    case ENDPOINTS.RECEIPTS_ADD_CANCEL_APPROVAL:
      return `${BASE_URLS.RECEIPTS}/${endpoint.params.id}/approveCancellation`;
    case ENDPOINTS.BRANCHES_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.RECEIPTS}/boms/branches`;
    case ENDPOINTS.CONTROLS_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.RECEIPTS}/boms/controls`;
    case ENDPOINTS.CONTROLS_GET_ALL:
      return `${BASE_URLS.RECEIPTS}/boms/controls/all`;
    case ENDPOINTS.PARTNERS_GET_AGENT_INFORMATION:
      return `${BASE_URLS.RECEIPTS}/agentInfo`;
    case ENDPOINTS.RECEIPTS_SEND_BODIES_TO_TRANSACTION:
      return `${BASE_URLS.RECEIPTS}/bodies`;
    case ENDPOINTS.INITIALIZE_TRANSACTION_CREATE:
      return `${BASE_URLS.RECEIPTS}/initialize`;
    case ENDPOINTS.GET_TRANSACTION:
      return `${BASE_URLS.RECEIPTS}/${endpoint.params.transactionId}/transaction`;

    // MODULES
    case ENDPOINTS.MODULES_GET_ALL:
      return BASE_URLS.MODULES;

    case ENDPOINTS.MODULES_GET_ONE_BY_ID:
    case ENDPOINTS.MODULES_UPDATE:
      return `${BASE_URLS.MODULES}/${endpoint.params.id}`;

    case ENDPOINTS.MODULES_RESET:
      return `${BASE_URLS.MODULES}/${endpoint.params.id}/reset`;

    // PERSONALIZATION
    case ENDPOINTS.PERSONALIZATION_GET:
    case ENDPOINTS.PERSONALIZATION_UPDATE:
      return BASE_URLS.PERSONALIZATION;

    case ENDPOINTS.PRIVILEGES_CREATE_ONE:
    case ENDPOINTS.PRIVILEGES_GET_ALL:
      return BASE_URLS.PRIVILEGES;

    case ENDPOINTS.PRIVILEGES_GET_ONE_BY_ID:
    case ENDPOINTS.PRIVILEGES_UPDATE:
    case ENDPOINTS.PRIVILEGES_DELETE:
      return `${BASE_URLS.PRIVILEGES}/${endpoint.params.id}`;

    case ENDPOINTS.PRIVILEGES_RESTORE:
      return `${BASE_URLS.PRIVILEGES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.PRIVILEGES_GET_RELEVANT:
      return `${BASE_URLS.PRIVILEGES}/relevant`;

    // NOTIFICATION
    case ENDPOINTS.NOTIFICATION_CERATE_WITHOUT_USER:
      return BASE_URLS.NOTIFICATION;

    case ENDPOINTS.NOTIFICATION_CREATE_MULTIPLE_NOTIFICATIONS_WITH_USERS:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_CERATE_WITH_USER:
      return `${BASE_URLS.NOTIFICATION}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_GET:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}`;

    case ENDPOINTS.NOTIFICATION_GET_USER_NOTIFICATIONS:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_FILTER:
      return BASE_URLS.NOTIFICATION;

    case ENDPOINTS.NOTIFICATIONS_MARK_AS_READ:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/read`;

    case ENDPOINTS.NOTIFICATIONS_MARK_ALL_AS_READ:
      return `${BASE_URLS.NOTIFICATION}/mark-all-read`;

    // CONFIGURABLE_FIELDS - VALIDATIONS
    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_NAME:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/name`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_PHONE_NUMBER:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/phone-number`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_ADDRESS:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/address`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_EMAIL:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/email`;

    // REFERENCES - PREDEFINED_REFERENCES
    case ENDPOINTS.PREDEFINED_REFERENCES_GET_VALUE:
      return `${BASE_URLS.REFERENCES.REFERENCES}/predefined-value`;

    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.REFERENCE_CATEGORIES_GET_ALL:
    case ENDPOINTS.REFERENCE_CATEGORIES_ADD_NEW:
      return BASE_URLS.REFERENCES.REFERENCE_CATEGORIES;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_SINGLE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/external/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/external/delete/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_UPDATE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/delete`;

    case ENDPOINTS.REFERENCE_CATEGORIES_RESTORE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_ADD_NEW:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL_V2:
      return `/v2${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;
    // REFERENCES - REFERENCES
    case ENDPOINTS.REFERENCE_EDIT:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}`;

    case ENDPOINTS.REFERENCE_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}/delete`;

    case ENDPOINTS.REFERENCE_RESTORE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}/restore`;

    case ENDPOINTS.REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;

    // REFERENCES - BULK ADD
    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_BULK_ADD:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references/bulk`;

    // REFERENCES - TEMPLATES
    case ENDPOINTS.TEMPLATES_ADD_NEW:
      return BASE_URLS.REFERENCES.TEMPLATES;

    case ENDPOINTS.TEMPLATES_UPDATE_ONE:
    case ENDPOINTS.TEMPLATES_GET_ONE:
      return `${BASE_URLS.REFERENCES.TEMPLATES}/${endpoint.params.id}`;

    // REFERENCES - FORMULA
    case ENDPOINTS.FORMULA_ANALYZE:
      return `${BASE_URLS.REFERENCES.FORMULA}/analyze`;

    case ENDPOINTS.FORMULA_EVALUATE:
      return `${BASE_URLS.REFERENCES.FORMULA}/evaluate`;

    // STORAGE - INTERNAL
    case ENDPOINTS.STORAGE_INTERNAL_UPLOAD:
      return `${BASE_URLS.STORAGE.INTERNAL}/upload`;

    case ENDPOINTS.STORAGE_INTERNAL_DELETE:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}`;
    case ENDPOINTS.STORAGE_INTERNAL_DELETE_ONE:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/delete`;

    case ENDPOINTS.STORAGE_INTERNAL_PREVIEW:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/preview`;

    case ENDPOINTS.STORAGE_INTERNAL_DOWNLOAD:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/download`;

    case ENDPOINTS.STORAGE_INTERNAL_SHARE_INTERNALLY:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/share/internal`;

    case ENDPOINTS.STORAGE_INTERNAL_CONTENT:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/content`;

    // SETUP - REFERENCE_MAPPING
    case ENDPOINTS.SETUP_GET_ALL_REFERENCE_MAPPINGS:
      return BASE_URLS.SETUP.REFERENCE_MAPPINGS;

    case ENDPOINTS.SETUP_GET_REFERENCE_MAPPING_BY_KEY:
      return `${BASE_URLS.SETUP.REFERENCE_MAPPINGS}/${endpoint.params.key}`;

    case ENDPOINTS.SETUP_UPDATE_REFERENCE_MAPPING:
      return `${BASE_URLS.SETUP.REFERENCE_MAPPINGS}/${endpoint.params.id}`;

    // SETUP - BACKUP_SCHEDULES
    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_ADD_NEW:
      return BASE_URLS.SETUP.BACKUP_SCHEDULES;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_GET_ALL:
      return BASE_URLS.SETUP.BACKUP_SCHEDULES;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_DELETE_ONE:
      return `${BASE_URLS.SETUP.BACKUP_SCHEDULES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_ENABLE_DISABLE_ONE:
      return `${BASE_URLS.SETUP.BACKUP_SCHEDULES}/${endpoint.params.id}/${endpoint.params.status}`;

    // SETUP - IAM
    case ENDPOINTS.SETUP_IAM_AUTHORIZERS_AZURE:
      return `${BASE_URLS.SETUP.AUTHORIZERS}/azure`;

    case ENDPOINTS.SETUP_IAM_HANDSHAKE:
      return `${BASE_URLS.SETUP.IAM_HANDSHAKE}`;

    // SETUP - BACKUPS
    case ENDPOINTS.SETUP_BACKUPS_BACKUP_NOW:
    case ENDPOINTS.SETUP_BACKUPS_GET_ALL:
      return BASE_URLS.SETUP.BACKUPS;

    case ENDPOINTS.SETUP_BACKUPS_DOWNLOAD_ONE:
    case ENDPOINTS.SETUP_BACKUPS_DELETE_ONE:
      return `${BASE_URLS.SETUP.BACKUPS}/${endpoint.params.name}`;

    // SETUP - LANGUAGES
    case ENDPOINTS.SETUP_LANGUAGES_ADD_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_GET_ALL:
      return BASE_URLS.SETUP.LANGUAGES;

    case ENDPOINTS.SETUP_LANGUAGES_GET_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_UPDATE_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_ENABLE_DISABLE_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_DELETE_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_LANGUAGES_REFRESH_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}/refresh`;

    case ENDPOINTS.SETUP_LANGUAGES_GET_CONTENT_OF_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}/content`;

    // SETUP - CURRENCIES
    case ENDPOINTS.SETUP_CURRENCIES_ADD_ONE:
    case ENDPOINTS.SETUP_CURRENCIES_GET_ALL:
      return BASE_URLS.SETUP.CURRENCIES;

    case ENDPOINTS.SETUP_CURRENCIES_GET_ONE:
    case ENDPOINTS.SETUP_CURRENCIES_UPDATE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_CURRENCIES_DELETE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/delete`;

    case ENDPOINTS.SETUP_CURRENCIES_RESTORE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.SETUP_CURRENCIES_TOGGLE_BASE_CURRENCY:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/base_currency`;

    // ASSETS
    case ENDPOINTS.ASSETS_IMAGES_UPLOAD:
      return BASE_URLS.ASSETS.IMAGES;

    case ENDPOINTS.ASSETS_IMAGES_DELETE:
      return `${BASE_URLS.ASSETS.IMAGES}/${endpoint.params.name}`;

    // SETUP - EXTERNAL SYSTEMS
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_GET_ALL:
      return BASE_URLS.SETUP.EXTERNAL_SYSTEMS;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEM_GET_EXTERNAL_REFERENCE_CATEGORIES:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS}/${endpoint.params.id}/reference-categories`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_SAVE_KEY:
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ALL:
      return BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GENERATE_NEW_KEY:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/generate`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ONE:
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_DELETE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/${endpoint.params.id}`;
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_RESTORE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/${endpoint.params.id}/restore`;

    // BUSINESS
    // POLICIES

    case ENDPOINTS.POLICIES_GET_ALL:
    case ENDPOINTS.POLICIES_ADD_NEW:
      return BASE_URLS.POLICIES;
    case ENDPOINTS.EXCHANGE_RATES_GET_ALL:
      return `${BASE_URLS.POLICIES}/exchange-rates`;
    case ENDPOINTS.POLICIES_DATA:
      return `${BASE_URLS.POLICIES}/policy-data`;
    case ENDPOINTS.POLICIES_ADD_NEW_VEHICLE_FLEET:
      return `${BASE_URLS.POLICIES}/vehicle-fleet`;
    case ENDPOINTS.POLICIES_ADD_NEW_MEDICAL_GROUP:
      return `${BASE_URLS.POLICIES}/medical-group`;
    case ENDPOINTS.POLICIES_ADD_NEW_TRAVEL_GROUP:
      return `${BASE_URLS.POLICIES}/travel-group`;
    case ENDPOINTS.POLICIES_SHARE_TO_CUSTOMER_LINK:
      return `${BASE_URLS.POLICIES}/share-data`;
    case ENDPOINTS.POLICIES_PREMIUM_CALCULATION:
      return `${BASE_URLS.POLICIES}/premium/${endpoint.params.type}`;
    case ENDPOINTS.POLICIES_GET_ALL_BOMS_PRODUCTS:
      return `${BASE_URLS.POLICIES}/boms/products`;
    case ENDPOINTS.POLICIES_GET_ALL_BOMS_PACKAGES:
      return `${BASE_URLS.POLICIES}/boms/packages`;
    case ENDPOINTS.POLICIES_GET_ONE_BOMS_WORKFLOW:
      return `${BASE_URLS.POLICIES}/boms/workflows/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_GET_ALL_BOMS_PARTNERS:
      return `${BASE_URLS.POLICIES}/boms/partners`;
    case ENDPOINTS.POLICIES_GET_BOMS_REFERENCES:
      return `${BASE_URLS.POLICIES}/boms/references`;
    case ENDPOINTS.POLICIES_GET_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_UPDATE_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_ADD_PROPOSAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal`;
    case ENDPOINTS.POLICIES_CANCEL_PROPOSAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/cancel`;
    case ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS_FINAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/verify`;
    case ENDPOINTS.POLICIES_VIEW_PROPOSAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/view`;
    case ENDPOINTS.POLICIES_PROPOSAL_RESUBMISSION:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/resubmit`;
    case ENDPOINTS.POLICIES_PROPOSAL_RAISED_DOCUMENTS_SUBMISSION:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/raised-requirements`;
    case ENDPOINTS.POLICIES_PROPOSAL_REQUEST_COVER_NOTE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/request-cover-note`;
    case ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/documents/verify`;
    case ENDPOINTS.POLICIES_RESUBMIT:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/resubmit`;
    case ENDPOINTS.POLICIES_DELETE_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_RESTORE_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;

    case ENDPOINTS.POLICIES_REQUEST_AMENDMENT:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/request-amendment`;
    case ENDPOINTS.POLICIES_REQUEST_AMENDMENT_STATUS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/request-amendment/status`;
    case ENDPOINTS.POLICIES_CANCEL_AMENDMENT:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/amendment/cancel`;
    case ENDPOINTS.POLICIES_UPDATE_DOC_DELETE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/doc/delete`;
    case ENDPOINTS.POLICIES_POLICY_CANCELLATION:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/policy/cancel`;
    case ENDPOINTS.POLICIES_REQUEST_POLICY_RENEWALS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/policy/request-renewal`;

    // COVER NOTES
    case ENDPOINTS.COVER_NOTES_GET_ALL:
    case ENDPOINTS.COVER_NOTES_ADD_NEW:
      return BASE_URLS.COVER_NOTES;
    case ENDPOINTS.COVER_NOTES_GET_ONE:
      return `${BASE_URLS.COVER_NOTES}/${endpoint.params.id}`;
    case ENDPOINTS.COVER_NOTES_UPDATE_ONE:
      return `${BASE_URLS.COVER_NOTES}/${endpoint.params.id}`;

    // ENDORSEMENTS
    case ENDPOINTS.ENDORSEMENTS_GET_ALL:
    case ENDPOINTS.ENDORSEMENTS_ADD:
      return BASE_URLS.ENDORSEMENTS;
    case ENDPOINTS.ENDORSEMENTS_GET_ONE:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}`;

    // FOMS
    // LEADS
    case ENDPOINTS.LEADS_ADD_NEW:
    case ENDPOINTS.LEADS_GET_ALL:
      return BASE_URLS.LEADS;

    case ENDPOINTS.LEADS_UPDATE_ONE:
      return `${BASE_URLS.LEADS}/${endpoint.params.id}/update`;
    case ENDPOINTS.LEADS_UPDATE_DOCUMENTS:
      return `${BASE_URLS.LEADS}/${endpoint.params.id}/documents-update`;

    case ENDPOINTS.LEADS_BULK_UPLOAD:
      return `${BASE_URLS.LEADS}/bulk`;

    case ENDPOINTS.LEADS_GET_ONE:
      return `${BASE_URLS.LEADS}/${endpoint.params.id}`;

    case ENDPOINTS.LEADS_RE_ASSIGN_AGENT:
      return `${BASE_URLS.LEADS}/${endpoint.params.id}/reassign`;

    case ENDPOINTS.LEADS_COMMENT:
      return `${BASE_URLS.LEADS}/${endpoint.params.id}/comments`;

    case ENDPOINTS.LEADS_GET_ALL_DOCUMENTATIONS:
      return `${BASE_URLS.LEADS}/docs/all`;

    case ENDPOINTS.LEADS_GENERATE_PUBLIC_API_KEY:
      return `${BASE_URLS.LEADS}/keys/generate`;
    case ENDPOINTS.LEADS_SAVE_GENERATED_API_KEY:
      return `${BASE_URLS.LEADS}/keys`;
    case ENDPOINTS.LEADS_GET_SPECIFIC_GENERATED_KEY:
      return `${BASE_URLS.LEADS}/keys/${endpoint.params.id}`;
    case ENDPOINTS.LEADS_GET_ALL_GENERATED_KEYS:
      return `${BASE_URLS.LEADS}/keys/getAll`;
    case ENDPOINTS.LEADS_DELETE_KEY:
      return `${BASE_URLS.LEADS}/keys/${endpoint.params.id}`;
    case ENDPOINTS.LEADS_RESTORE_KEY:
      return `${BASE_URLS.LEADS}/keys/${endpoint.params.id}/restore`;

    // RECRUITMENTS
    case ENDPOINTS.RECRUITMENTS_ADD_NEW:
    case ENDPOINTS.RECRUITMENTS_GET_ALL:
      return BASE_URLS.RECRUITMENTS;

    case ENDPOINTS.RECRUITMENT_GENERATE_CODE:
      return `${BASE_URLS.RECRUITMENTS}/generate_code/new`;

    case ENDPOINTS.RECRUITMENTS_UPDATE_ONE:
      return `${BASE_URLS.RECRUITMENTS}/${endpoint.params.id}`;

    case ENDPOINTS.RECRUITMENTS_GET_ONE:
    case ENDPOINTS.RECRUITMENT_RESCHEDULE_INTERVIEW:
      return `${BASE_URLS.RECRUITMENTS}/${endpoint.params.id}`;

    case ENDPOINTS.RECRUITMENT_EXIT_INTERVIEW:
      return `${BASE_URLS.RECRUITMENTS}/${endpoint.params.id}/exit`;

    case ENDPOINTS.RECRUITMENT_SCHEDULE_INTERVIEW:
      return `${BASE_URLS.RECRUITMENTS}/${endpoint.params.id}/schedule`;

    case ENDPOINTS.RECRUITMENT_INTERVIEW_ADD:
      return `${BASE_URLS.RECRUITMENTS}/interview`;

    case ENDPOINTS.RECRUITMENT_INTERVIEW_UPDATE:
      return `${BASE_URLS.RECRUITMENTS}/interview/${endpoint.params.id}`;

    case ENDPOINTS.RECRUITMENT_INTERVIEW_GET_ONE:
      return `${BASE_URLS.RECRUITMENTS}/interview/${endpoint.params.id}`;

    case ENDPOINTS.RECRUITMENT_INTERVIEW_GET_ONE_BY_RECRUITMENT_ID:
      return `${BASE_URLS.RECRUITMENTS}/interview/${endpoint.params.id}/recruitment`;

    case ENDPOINTS.RECRUITMENT_INTERVIEW_SUBMIT:
      return `${BASE_URLS.RECRUITMENTS}/interview/${endpoint.params.id}/submit`;

    // Activities
    case ENDPOINTS.ACTIVITIES_GET_ALL:
    case ENDPOINTS.ACTIVITIES_ADD_NEW:
      return BASE_URLS.ACTIVITIES;

    case ENDPOINTS.ACTIVITIES_DELETE_ONE:
    case ENDPOINTS.ACTIVITIES_UPDATE_ONE:
      return `${BASE_URLS.ACTIVITIES}/${endpoint.params.id}`;

    case ENDPOINTS.ACTIVITIES_SCHEDULE_ONE:
      return `${BASE_URLS.ACTIVITIES}/schedule`;

    case ENDPOINTS.ACTIVITIES_COMPLETE_ONE:
      return `${BASE_URLS.ACTIVITIES}/${endpoint.params.id}/complete`;

    case ENDPOINTS.ACTIVITIES_RESCHEDULE_ONE:
      return `${BASE_URLS.ACTIVITIES}/${endpoint.params.id}/reschedule`;

    case ENDPOINTS.ACTIVITIES_RESCHEDULE_INTERVIEW:
      return `${BASE_URLS.ACTIVITIES}/${endpoint.params.id}/interview-reschedule`;

    case ENDPOINTS.ACTIVITIES_CANCEL_INTERVIEW:
      return `${BASE_URLS.ACTIVITIES}/${endpoint.params.id}/interview-cancel`;

    //Competitions
    case ENDPOINTS.COMPETITIONS_ADD_NEW:
    case ENDPOINTS.COMPETITIONS_GET_ALL:
      return BASE_URLS.COMPETITIONS;
    case ENDPOINTS.COMPETITIONS_GET_ONE:
      return `${BASE_URLS.COMPETITIONS}/${endpoint.params.id}`;
    case ENDPOINTS.COMPETITIONS_UPDATE_ONE:
      return `${BASE_URLS.COMPETITIONS}/${endpoint.params.id}`;
    case ENDPOINTS.COMPETITIONS_UPDATE_STATUS:
      return `${BASE_URLS.COMPETITIONS}/${endpoint.params.id}/status`;
    case ENDPOINTS.COMPETITIONS_EXTEND:
      return `${BASE_URLS.COMPETITIONS}/${endpoint.params.id}/extends`;
    case ENDPOINTS.COMPETITIONS_STOP:
      return `${BASE_URLS.COMPETITIONS}/${endpoint.params.id}/stop`;
    case ENDPOINTS.COMPETITIONS_LEADERBOARD_AGENTS:
      return `${BASE_URLS.COMPETITIONS}/${endpoint.params.id}/leaderboard`;

    //PAYOUTS

    case ENDPOINTS.PAYOUTS_GET_ALL:
      return BASE_URLS.PAYOUTS.DATA;
    case ENDPOINTS.PAYOUTS_UPDATE_STATUS_AND_DATA:
      return `${BASE_URLS.PAYOUTS.DATA}/${endpoint.params.id}/status-and-data`;
    case ENDPOINTS.PAYOUTS_CONFIG_BATCH_SCHEDULE_ADD_NEW:
    case ENDPOINTS.PAYOUTS_CONFIG_BATCH_SCHEDULE_GET_ALL:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/batch-schedules`;
    case ENDPOINTS.PAYOUTS_CONFIG_BATCH_SCHEDULE_UPDATE:
    case ENDPOINTS.PAYOUTS_CONFIG_BATCH_SCHEDULE_DELETE:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/batch-schedules/${endpoint.params.id}`;
    case ENDPOINTS.PAYOUTS_CONFIG_EARNINGS_SET_EARNINGS:
    case ENDPOINTS.PAYOUTS_CONFIG_EARNINGS_GET_ALL:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/earnings`;
    case ENDPOINTS.PAYOUTS_CONFIG_EARNINGS_GET_RUNNING:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/earnings/running_batch`;
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_ADD_NEW:
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_GET_ALL_BY_BATCH_ID:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/batch-schedules/${endpoint.params.batch_id}/deductions`;
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_GET_ALL_AGENT_WISE:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/deductions`;
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_ADD_BULK:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/deductions/bulk`;
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_GET_ALL_GENERAL:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/deductions/all`;
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_UPDATE_ONE:
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_DELETE_ONE:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/deductions/${endpoint.params.id}`;
    case ENDPOINTS.PAYOUTS_CONFIG_DEDUCTIONS_ADD_NEW_CRITERIA:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/deductions/${endpoint.params.criteria_id}/criteria`;
    case ENDPOINTS.PAYOUTS_CONFIG_ENTRIES_ADD_NEW:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/entries`;
    case ENDPOINTS.PAYOUTS_CONFIG_ENTRIES_GET_ALL:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/entries`;
    case ENDPOINTS.PAYOUTS_CONFIG_DATA_MAPPINGS_SET_DATA_MAPPINGS:
    case ENDPOINTS.PAYOUTS_CONFIG_DATA_MAPPINGS_GET_ALL:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/data-mappings`;
    case ENDPOINTS.PAYOUTS_CONFIG_GET_BANK_ORDER_FIELDS:
    case ENDPOINTS.PAYOUTS_CONFIG_SET_BANK_ORDER_FIELDS:
      return `${BASE_URLS.PAYOUTS.CONFIGURATIONS}/bank-orders`;
    case ENDPOINTS.PAYOUTS_TRANSACTIONS:
      return BASE_URLS.PAYOUTS_ALL;
    case ENDPOINTS.PAYOUTS_UPDATE_STATUS:
      return `${BASE_URLS.PAYOUTS_ALL}/${endpoint.params.id}/status`;
    case ENDPOINTS.PAYOUTS_PROCESS_ALL:
      return `${BASE_URLS.PAYOUTS_ALL}/process-all`;
    case ENDPOINTS.PAYOUTS_COUNT:
      return `${BASE_URLS.PAYOUTS_ALL}/count`;
    case ENDPOINTS.PAYOUTS_UPDATE_PAYOUTS:
      return `${BASE_URLS.PAYOUTS_ALL}/${endpoint.params.id}`;
    case ENDPOINTS.PAYOUTS_GENERATE_VOUCHER_NUMBER_AND_BATCH_ID:
      return `${BASE_URLS.PAYOUTS_ALL}/process-payments`;

    //ACCOUNTING
    //CHART OF ACCOUNTS

    case ENDPOINTS.CHART_OF_ACCOUNT_ADD_NEW:
    case ENDPOINTS.CHART_OF_ACCOUNT_GET_ALL:
      return BASE_URLS.CHART_OF_ACCOUNTS;
    case ENDPOINTS.CHART_OF_ACCOUNT_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/all/partial`;
    case ENDPOINTS.CHART_OF_ACCOUNT_DELETE_ONE:
    case ENDPOINTS.CHART_OF_ACCOUNT_UPDATE_ONE:
    case ENDPOINTS.CHART_OF_ACCOUNT_GET_ONE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}`;

    case ENDPOINTS.CHART_OF_ACCOUNT_RESTORE_ONE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}/restore`;

    case ENDPOINTS.CHART_OF_ACCOUNT_DISABLE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}/disable`;

    case ENDPOINTS.CHART_OF_ACCOUNT_ENABLE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}/enable`;

    // ENTRIES
    case ENDPOINTS.ENTRIES_ADD_NEW:
    case ENDPOINTS.ENTRIES_GET_ALL:
    case ENDPOINTS.ENTRIES_UPDATE_ONE:
      return BASE_URLS.ENTRIES;
    case ENDPOINTS.ENTRIES_GET_ONE_BY_DEDUCTION_CONFIG:
      return `${BASE_URLS.ENTRIES}/deduction-entries`;

    // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.CLIENT_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.CLIENT_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.CLIENT_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.CLIENT_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.EXTERNAL_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.EXTERNAL_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.EXTERNAL_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.EXTERNAL_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;

    case ENDPOINTS.EXTERNAL_REFERENCES_PRODUCTS_GET_ALL:
      return `${BASE_URLS.REFERENCES.EXTERNAL_REFERENCES_PRODUCTS_GET_ALL}`;
    // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.IAM_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.IAM_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.IAM_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.IAM_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //

    case ENDPOINTS.TARGETS_CONFIG_GET_ALL:
    case ENDPOINTS.TARGETS_CONFIG_UPDATE:
      return BASE_URLS.TARGETS.CONFIGURATIONS;

    case ENDPOINTS.TARGETS_ADD_NEW:
    case ENDPOINTS.TARGETS_GET_ALL:
      return BASE_URLS.TARGETS.DATA;

    case ENDPOINTS.TARGETS_GET_ONE:
      return `${BASE_URLS.TARGETS.DATA}/${endpoint.params.year}/${endpoint.params.month}`;

    case ENDPOINTS.TARGETS_DELETE_ONE:
      return `${BASE_URLS.TARGETS.DATA}/${endpoint.params.id}`;

    case ENDPOINTS.TARGETS_ARCHIVE_ONE:
      return `${BASE_URLS.TARGETS.DATA}/${endpoint.params.id}/archive`;

    // ACHIEVEMENTS
    case ENDPOINTS.ACHIEVEMENTS_CONFIG_GET_ALL:
      return BASE_URLS.ACHIEVEMENTS.CONFIGURATIONS;
    case ENDPOINTS.ACHIEVEMENTS_CONFIG_UPDATE:
      return BASE_URLS.ACHIEVEMENTS.CONFIGURATIONS;
    case ENDPOINTS.ACHIEVEMENTS_CONFIG_UPDATE_TARGET:
      return BASE_URLS.ACHIEVEMENTS.CONFIGURE_ACHIEVEMENT_TARGETS;
    case ENDPOINTS.ACHIEVEMENTS_LOCK_DATE_CONFIG_GET:
      return BASE_URLS.ACHIEVEMENTS.CONFIGURATIONS_LOCK_DATE;
    case ENDPOINTS.ACHIEVEMENTS_LOCK_DATE_CONFIG_UPDATE:
      return BASE_URLS.ACHIEVEMENTS.CONFIGURATIONS_LOCK_DATE;

    case ENDPOINTS.ACHIEVEMENTS_GET_ALL:
      return BASE_URLS.ACHIEVEMENTS.DATA;

    case ENDPOINTS.ACHIEVEMENTS_GET_ONE:
      return `${BASE_URLS.ACHIEVEMENTS.DATA}/${endpoint.params.year}/${endpoint.params.month}`;

    case ENDPOINTS.ACHIEVEMENTS_EXPORT:
      return `${BASE_URLS.ACHIEVEMENTS.DATA}/export`;
  }
};

export const generateURL = (endpoint: EndpointParams): string => {
  const path = getURLPath(endpoint);
  const apiVersion = endpoint.version ? `/v${endpoint.version}` : '';
  const clientPrefix = endpoint.clientType ?? '';

  return `/api${apiVersion}${clientPrefix}${path}`;
};
